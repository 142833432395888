

























































































import { ValidationProvider } from "vee-validate";
import { Component, Vue, Prop } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import SearchDossierResults from "./SearchDossierResults.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import { PaginatedList } from "@/api/models/common/paginatedList";
import {
  BSpinner
} from "bootstrap-vue";

@Component({
  components: {
    ValidationProvider,
    SearchableVueSelect,
    SearchDossierResults,
    RessifnetDateInput,
    BSpinner
  },
})
export default class ListeDossiersAttenteRgpd extends Vue {
  @Prop({ required: true, default: "Liste des dossiers " }) title!: string;
  @Prop({ required: true, default: null }) dossiers!: PaginatedList<any>;
  @Prop({ required: false }) loading!: boolean
  pageSize = 10;
  pageNumber = 1;
}
