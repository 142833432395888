
















































































































































































































































































































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Component, Vue, Watch } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { Site } from "@/api/models/options/entreprises/site";
import { DossierSearchParams } from "@/api/models/dossiers/dossier";
import SearchDossierResults from "./searchDossier/SearchDossierResults.vue";
import ListeDossiersAttenteRgpd from "./searchDossier/ListeDossiersAttenteRgpd.vue";
import { BButton, BSpinner, BFormInput, BImg, BAvatar } from "bootstrap-vue";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import store from '@/store/index'
import moment from 'moment'

@Component({
  components: {
    BButton,
    BSpinner,
    BFormInput,
    BImg,
    BAvatar,
    SearchableVueSelect,
    SearchDossierResults,
    ListeDossiersAttenteRgpd,
    ValidationProvider,
    ValidationObserver,
    VuePerfectScrollbar,
  },
})
export default class AccompagnementIndividuel extends Vue {
  required = required
  refreshIndex = 1;
  
  showAutocompleteNom = false
  showAutocompleteNumero = false
  timeout: any = null
  perfectScrollbarSettings = {
    maxScrollbarLength: 100,
  }
  currentSelectedNom: any = null
  currentSelectedNumero: any = null

  search: DossierSearchParams = {};

  pageSize = 10;
  pageNumber = 1;

  sortBy='numeroDossier'
  isSortDirDesc = false

  searchLoading = false;
  autocompleteNomLoading = false;
  autocompleteNumeroLoading = false;
  lastSeenLoading = false;
  attenteRgpdLoading = true;

  searchEntreprisesTotalCount = 0;
  searchEntreprisesItems: Entreprise[] = [];
  
  searchSitesTotalCount = 0;
  searchSitesItems: Site[] = [];

  searchResult: PaginatedList<any> | null = null;
 
  searchResultAutocompleteNom: any[] = []
  searchResultAutocompleteNumero: any[] = []
  searchAutocompleteNomTotalCount = 0;
  searchAutocompleteNumeroTotalCount = 0;
  lastSeenResult: PaginatedList<any> | null = null;

  dossiersAttenteRGPD: PaginatedList<any> = new PaginatedList<any>();

  async created() {
    if (store.state.searchDossier != undefined)
    {
      this.search = store.state.searchDossier
      await this.searchEntreprise({onCreate: true})
      await this.searchSites({onCreate: true})
      await this.searchDossiers({})
    }
      
    await this.getLasts();
    // await this.getDossiersAttenteRGPD();
  }

  calculerAge(birthday: any) {
    var today = moment();
    var birthdate = moment(birthday, 'DD-MM-YYYY');
    const age = today.diff(birthdate, 'years');
    return age;
  }

  @Watch("search")
  storeSearchDossier() {
    store.state.searchDossier = this.search
  }

  isDisabled(string: string) {
    return string != undefined && string != null && string != ""
  }

  @Watch('search.entrepriseId')
  async currentPageChange(){
    if(this.search.entrepriseId === null)
    {
      this.search.siteId = ""
    }
  }

  hideAutocomplete() {
    this.showAutocompleteNom = false
    this.showAutocompleteNumero = false
  }

  goToDossier(dossierId: string) {
    this.$router.push({ name: 'details-accompagnement-individuel', params: { id: dossierId } }).catch(()=>{});
  }

  updateSort(params: any){
    this.sortBy = params.sortBy
    this.isSortDirDesc = params.isSortDirDesc
    this.searchDossiers(params)
  }

  async searchEntreprise(params: any) {
    if (!params || params?.reset) {
      this.searchEntreprisesTotalCount = 0
      this.searchEntreprisesItems = this.searchEntreprisesItems?.filter(e => e.id == this.search.entrepriseId);
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        "",
        params.onCreate ? this.search.entrepriseId : ""
      )
      .then((response: PaginatedList<Entreprise>) => {
        this.searchEntreprisesTotalCount = response.totalCount;
        this.searchEntreprisesItems = this.searchEntreprisesItems!.concat(response.items.filter(ri => !this.searchEntreprisesItems!.some(lsi => lsi.id == ri.id)))
        // this.search.entrepriseId = this.searchEntreprisesItems.length == 1 ? this.searchEntreprisesItems[0].id : undefined;
        // if(this.search.entrepriseId != undefined) this.searchSites(null);
      });
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.searchSitesTotalCount = 0;
      this.searchSitesItems = this.searchSitesItems?.filter(e => e.id == this.search.siteId);
    }

    await this.$http.ressifnet.sites
      .paginatedList(
        this.search.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        "",
        "",
        "",
        "",
        params?.onCreate ? this.search.siteId : ""
      )
      .then((response: PaginatedList<Site>) => {
        this.searchSitesTotalCount = response.totalCount;
        this.searchSitesItems = this.searchSitesItems!.concat(response.items.filter(ri => !this.searchSitesItems!.some(lsi => lsi.id == ri.id)))
        // this.search.siteId = this.searchSitesItems.length === 1 ? this.searchSitesItems[0].id : undefined;
      });
  }

  async searchDossiers(params: any) {
    store.state.searchDossier = this.search;
    this.searchLoading = true;
    await this.$http.ressifnet.dossiers
      .search({
        ...this.search,
        pageNumber: params.pageIndex,
        pageSize: this.pageSize,
        orderBy: params.sortBy,
        orderByDescending: params.isSortDirDesc
      })
      .then((response: PaginatedList<any>) => {
        this.searchResult = response;
        this.searchLoading = false;
        
        setTimeout(() => {
          let formCard = (this.$refs['searchDossierTable'] as any);
          if (formCard != null)
            formCard.scrollIntoView({ behavior: 'smooth' })
        }, 350)
      });
  }

  @Watch("search.nomPersonne")
  async searchDossiersAutocompleteNom(params: any) {
    if(
      (!this.search.nomPersonne && !this.search.numeroDossier) || 
      (this.search.nomPersonne && this.search.nomPersonne.length < 2))
      return;


    this.searchResultAutocompleteNumero = [];
    this.searchAutocompleteNumeroTotalCount = 0;

    this.showAutocompleteNom = true;
    this.autocompleteNomLoading = true
    clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      await this.$http.ressifnet.dossiers
        .search({
          nomPersonne: this.search.nomPersonne,
          entrepriseId: this.search?.entrepriseId ?? '',
          siteId: this.search?.siteId ?? '',
          pageNumber: 1,
          pageSize: 10,
          withoutCount: true
        })
        .then((response: any) => {
          this.searchResultAutocompleteNom = response.items;
          this.searchAutocompleteNomTotalCount = response.totalCount;
          this.autocompleteNomLoading = false;
        })
    }, 1500)
  }

  @Watch("search.numeroDossier")
  async searchDossiersAutocompleteNumero(params: any) {   
    this.searchResultAutocompleteNumero = [];
    this.searchAutocompleteNumeroTotalCount = 0;

    if(
      (!this.search.nomPersonne && !this.search.numeroDossier) || 
      (this.search.numeroDossier && this.search.numeroDossier.length < 3)
    )
      return;

    this.showAutocompleteNumero = true;
    this.autocompleteNumeroLoading = true
    clearTimeout(this.timeout)
    this.timeout = setTimeout(async () => {
      await this.$http.ressifnet.dossiers
        .search({
          numeroDossier: this.search.numeroDossier,
          entrepriseId: this.search?.entrepriseId ?? '',
          siteId: this.search?.siteId ?? '',
          pageNumber: 1,
          pageSize: 10,
          withoutCount: true
        })
        .then((response: any) => {
          this.searchResultAutocompleteNumero = response.items;
          this.searchAutocompleteNumeroTotalCount = response.totalCount;
          this.autocompleteNumeroLoading = false;
        })
    }, 1500)
  }

  async getLasts() {
    this.lastSeenLoading = true;
    await this.$http.ressifnet.dossiers.getLasts().then(
      (response: PaginatedList<any>) => {
        this.lastSeenResult = response;
        this.lastSeenLoading = false;
      },
      (error: any) => {
        errorAlert.fire({
          title:
            "Erreur lors du chargement des dernières entreprises consultées",
        });
      }
    );
  }

  async getDossiersAttenteRGPD() {
    this.attenteRgpdLoading = true
    await this.$http.ressifnet.dossiers.getWaitingRGPD().then(
      (response: PaginatedList<any>) => {
        this.dossiersAttenteRGPD = response;
        this.attenteRgpdLoading = false
      },
      (error: any) => {
        errorAlert.fire({
          title:
            "Erreur lors du chargement des dernières entreprises consultées",
        });
      }
    );
  }

  async onEntrepriseSelected(val: any) {
    if (val) {
      (this.$refs['siteSearchSelect'] as any).resetParams()
      const entreprise = this.searchEntreprisesItems?.filter(s => s.id == val)[0];
      this.search.siteId = undefined;
      this.$set(this.search, 'siteId', undefined);
      this.searchSitesItems = [];
      await this.searchSites({});
    }
  }

}
